import React from "react";
import NativeVideo from "../NativeVideo";
import { VideoViewerType } from "../helpers";

interface ResizableViewerProps {
    videoOptions: any;
    mapEl: React.RefObject<HTMLDivElement>,
    orientation: "vertical" | "horizontal";
    changeCurrentViewer: (viewer: VideoViewerType) => void;
}

export default function ResizableViewer(props: ResizableViewerProps) {
    const { videoOptions, mapEl, orientation, changeCurrentViewer } = props;
    const [size, setSize] = React.useState(50);
    const startPosRef = React.useRef<number>(0);
    const startSizeRef = React.useRef<number>(50);
    const containerRef = React.useRef<HTMLDivElement>(null);

    const handleMouseDown = (e: React.MouseEvent) => {
        if (!containerRef.current) return;
        startPosRef.current = orientation === "vertical" ? e.clientX : e.clientY;
        startSizeRef.current = size;

        const onMouseMove = (moveEvent: MouseEvent) => handleDrag(moveEvent as unknown as React.MouseEvent);
        const onMouseUp = () => document.removeEventListener("mousemove", onMouseMove);

        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp, { once: true });
    };

    const handleDrag = (e: React.MouseEvent) => {
        if (!containerRef.current) return;

        const totalSize = orientation === "vertical" ? containerRef.current.clientWidth : containerRef.current.clientHeight;
        const delta = orientation === "vertical" ? e.clientX - startPosRef.current : e.clientY - startPosRef.current;
        const newSize = startSizeRef.current + (delta / totalSize) * 100;

        setSize(Math.max(25, Math.min(75, newSize)));
    };

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                maxHeight: "calc(-168px + 100vh)",
                display: "flex",
                flexDirection: orientation === "vertical" ? "row" : "column",
                position: "relative",
            }}
            ref={containerRef}
        >
            <div
                style={{
                    width: orientation === "vertical" ? `${size}%` : "100%",
                    height: orientation === "vertical" ? "100%" : `${size}%`,
                    overflow: "hidden",
                }}
            >
                <NativeVideo {...videoOptions} currentViewer={orientation} changeCurrentViewer={changeCurrentViewer} />
            </div>

            <div
                style={{
                    width: orientation === "vertical" ? "5px" : "100%",
                    height: orientation === "vertical" ? "100%" : "5px",
                    cursor: orientation === "vertical" ? "col-resize" : "row-resize",
                    background: "#FFF",
                    zIndex: 10,
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                onMouseDown={handleMouseDown}
            >
                <div
                    style={{
                        width: orientation === "vertical" ? "14px" : "28px",
                        height: orientation === "vertical" ? "28px" : "14px",
                        background: "#FFF",
                        borderRadius: "2px",
                        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.40)",
                        display: "flex",
                        flexDirection: orientation === "vertical" ? "row" : "column",
                        alignItems: "center",
                        justifyContent: "center",
                        userSelect: "none",
                        padding: "6px 4px",
                        gap: "2px",
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            border: "1px solid black",
                        }}
                    ></div>
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            border: "1px solid black",
                        }}
                    ></div>
                </div>
            </div>

            <div
                ref={mapEl}
                style={{
                    width: orientation === "vertical" ? `${100 - size}%` : "100%",
                    height: orientation === "vertical" ? "100%" : `${100 - size}%`,
                    background: "#e0e0e0",
                }}
            ></div>
        </div>
    );
}
