import { makeStyles } from "@fluentui/react-components";
import EarthIcon from "../../assets/icons/ol/video/earth.svg";
import HillIcon from "../../assets/icons/ol/video/hill.svg";
import { VideoViewerType } from "../video/helpers";


interface VideoLayerInfoProps {
    visible: boolean;
    info: {
        lat: string;
        lng: string;
        alt: string;
    }
    viewer: VideoViewerType
}
export default function VideoLayerInfo(props: VideoLayerInfoProps) {
    const { visible, info: { lat, lng, alt }, viewer } = props;
    const classes = useStyles();

    if (!visible) {
        return null;
    }

    const renderLatLngData = <div className={classes.container} style={{ backgroundColor: viewer !== "pip" ? "rgba(255, 255, 255, 0.60)" : "white" }}>
        <img src={EarthIcon} alt="lat long" />
        <div className={classes.textContainer}>
            <p>{lat} °N</p>
            <p>{lng} °W</p>
        </div>
    </div>

    const renderAltData = <div className={classes.container} style={{ backgroundColor: viewer !== "pip" ? "rgba(255, 255, 255, 0.60)" : "white" }}>
        <img src={HillIcon} alt="altitute" />
        <p>{alt} meters</p>
    </div>

    if (viewer === "pip") {
        return <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            {renderAltData}
            {renderLatLngData}
        </div>
    }

    if (viewer === "horizontal") {
        return <div style={{ display: "flex", gap: "16px" }}>
            {renderAltData}
            {renderLatLngData}
        </div>
    }

    return <>
        {renderAltData}
        {renderLatLngData}
    </>

}

const useStyles = makeStyles({
    horizontalViewContainer: {
        position: "absolute",
        left: "24px"
    },
    container: {
        padding: "8px 8px",
        display: "flex",
        gap: "16px",
        borderRadius: "8px",
        color: "#000",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "normal",
        zIndex: 100,
    },
    textContainer: {
        display: "flex",
        gap: "8px"
    }
});