import "cesium/Build/Cesium/Widgets/widgets.css";
import { useEffect, useRef, useState } from "react";
import * as Cesium from 'cesium';
import { Button } from "@fluentui/react-components";

interface Props {
    tilesetUrl: string;
}

export const ThreeDimensionalModelViewer = ({ tilesetUrl }: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const viewerRef = useRef<Cesium.Viewer | null>(null);
    const [isMeasuring, setIsMeasuring] = useState(false)

    let points: Cesium.Cartesian3[] = [];

    function calculateDistance(point1: Cesium.Cartesian3, point2: Cesium.Cartesian3) {
        return Cesium.Cartesian3.distance(point1, point2);
    }

    const measureDistance = (click: { position: Cesium.Cartesian2 }) => {
        const viewer = viewerRef.current;
        if (!viewer) return;

        const pickedPosition = viewer.scene.pickPosition(click.position);
        if (Cesium.defined(pickedPosition)) {
            if (points.length % 2 === 0) viewer.entities.removeAll();
            points.push(pickedPosition);
            viewer.entities.add({
                position: pickedPosition,
                point: {
                    pixelSize: 10,
                    color: Cesium.Color.YELLOW
                }
            });

            if (points.length === 2) {
                const distance = calculateDistance(points[0], points[1]);

                viewer.entities.add({
                    polyline: {
                        positions: points,
                        width: 5,
                        material: Cesium.Color.RED
                    }
                });

                const midpoint = Cesium.Cartesian3.midpoint(
                    points[0],
                    points[1],
                    new Cesium.Cartesian3()
                );

                viewer.entities.add({
                    position: midpoint,
                    label: {
                        text: `${distance.toFixed(2)} meters`,
                        font: '14px sans-serif',
                        fillColor: Cesium.Color.WHITE,
                        style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                        outlineWidth: 2,
                        outlineColor: Cesium.Color.BLACK,
                        pixelOffset: new Cesium.Cartesian2(0, -10),
                        showBackground: true,
                        backgroundColor: new Cesium.Color(0.165, 0.165, 0.165, 0.7),
                        backgroundPadding: new Cesium.Cartesian2(7, 5),
                        disableDepthTestDistance: Number.POSITIVE_INFINITY
                    }
                });

                points = [];
            }
        }
    }

    const handleToggleMeasurement = () => {
        const viewer = viewerRef.current;
        if (!viewer) return;

        if (isMeasuring) {
            viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
            viewer.entities.removeAll();
        } else {
            viewer.screenSpaceEventHandler.setInputAction(measureDistance, Cesium.ScreenSpaceEventType.LEFT_CLICK);
        }
        setIsMeasuring(!isMeasuring)
    }

    useEffect(() => {
        const existingViewers = document.querySelectorAll('.cesium-viewer');
        existingViewers.forEach(viewer => {
            viewer.remove();
        });

        const createViewer = async () => {
            if (!containerRef.current) return;

            try {
                if (viewerRef.current) {
                    viewerRef.current.destroy();
                    viewerRef.current = null;
                }

                const viewer = new Cesium.Viewer(containerRef.current, {
                    terrainProvider: await Cesium.createWorldTerrainAsync()
                });

                viewerRef.current = viewer;

                const tileset = await Cesium.Cesium3DTileset.fromUrl(
                    tilesetUrl
                );
                viewer.scene.primitives.add(tileset);
                await viewer.flyTo(tileset);

            } catch (error) {
                console.error('Failed to create Cesium viewer:', error);
                if (viewerRef.current) {
                    viewerRef.current.destroy();
                    viewerRef.current = null;
                }
            }
        };

        const timeoutId = setTimeout(() => {
            createViewer();
        }, 100);

        return () => {
            clearTimeout(timeoutId);
            if (viewerRef.current) {
                try {
                    viewerRef.current.destroy();
                    viewerRef.current = null;
                } catch (e) {
                    console.error('Error during cleanup:', e);
                }
            }
        };
    }, []);

    return (
        <div
            style={{
                position: 'relative',
                width: '100%',
                height: '75vh',
            }}
        >
            <div
                ref={containerRef}
                style={{
                    width: '100%',
                    height: '100%'
                }}
            />
            <div
                style={{
                    position: 'absolute',
                    top: 55,
                    right: 5
                }}
            >
                <Button onClick={handleToggleMeasurement} appearance="primary">
                    Toggle Measurement {isMeasuring ? 'Off' : 'On'}
                </Button>
            </div>
        </div>
    );
};