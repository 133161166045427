import { makeStyles } from '@fluentui/react-components';
import { useEffect, useRef, useState } from 'react';
import HorizontalIcon from "../../assets/icons/video/horizontal.svg";
import HorizontalSelectedIcon from "../../assets/icons/video/horizontal_selected.svg";
import PauseIcon from "../../assets/icons/video/pause.svg";
import PipIcon from "../../assets/icons/video/pip.svg";
import PipSelectedIcon from "../../assets/icons/video/pip_selected.svg";
import PlayIcon from "../../assets/icons/video/play_arrow.svg";
import VerticalIcon from "../../assets/icons/video/vertical.svg";
import VerticalSelectedIcon from "../../assets/icons/video/vertical_selected.svg";
import { VideoViewerType } from './helpers';

interface NativeVideoProps {
    videoUrl: string;
    time: any;
    duration: any;
    setTime: any;
    setDuration: any;
    currentViewer: VideoViewerType;
    changeCurrentViewer: (viewer: VideoViewerType) => void;
}

const NativeVideo = (props: NativeVideoProps) => {
    const { videoUrl, setTime, time, duration, setDuration, currentViewer, changeCurrentViewer } = props;
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.onloadedmetadata = () => {
                if (videoRef.current?.duration) {
                    setDuration(videoRef?.current?.duration);
                }
                if (videoRef.current) {
                    videoRef.current.currentTime = time
                }
            };

            const interval = setInterval(() => {
                if (isPlaying) {
                    if (videoRef.current?.currentTime)
                        setTime(videoRef.current.currentTime);
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [isPlaying]);

    const handlePlayPause = () => {
        if (!videoRef.current) {
            return;
        }
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleSeek = (e: any) => {
        if (!videoRef.current) {
            return;
        }
        const newTime = (e.target.value / 100) * duration;
        videoRef.current.currentTime = newTime;
        setTime(newTime);
    };

    const progress = (time / duration) * 100 || 0;

    return (
        <div className={classes.videoContainer}>
            <video
                autoPlay
                ref={videoRef}
                className={classes.videoPlayer}
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
            >
                <source src={videoUrl} type="video/mp4" />
            </video>

            <div className={classes.videoControlsContainer}>
                <div className={classes.videoControls}>
                    <input
                        className={classes.seekBar}
                        type="range"
                        min="0"
                        max="100"
                        value={(time / duration) * 100}
                        onChange={handleSeek}
                        style={{
                            background: `linear-gradient(to right, black ${progress}%, #9B9B9B ${progress}%)`,
                        }}
                    />

                    <div className={classes.videoInfoContainer}>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "24px",
                        }}>
                            <img src={isPlaying ? PauseIcon : PlayIcon} alt="play pause toggle" height={24} width={24} style={{ cursor: "pointer" }} onClick={handlePlayPause} />

                            <p className={classes.timeDisplay}>
                                {formatTime(time)} / {formatTime(duration)}
                            </p>
                        </div>

                        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                            <img src={currentViewer === "vertical" ? VerticalSelectedIcon : VerticalIcon} alt="vertical" onClick={() => { changeCurrentViewer("vertical") }} style={{ cursor: "pointer" }} />
                            <img src={currentViewer === "horizontal" ? HorizontalSelectedIcon : HorizontalIcon} alt="horizontal" onClick={() => { changeCurrentViewer("horizontal") }} style={{ cursor: "pointer" }} />
                            <img src={currentViewer === "pip" ? PipSelectedIcon : PipIcon} alt="pip" onClick={() => { changeCurrentViewer("pip") }} style={{ cursor: "pointer" }} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};


const useStyles = makeStyles({
    videoContainer: {
        position: "relative",
        width: "100%",
        height: "100%",
        maxHeight: "calc(-168px + 100vh)"
    },
    videoPlayer: {
        width: "100%",
        height: "100%",
        display: "block",
        objectFit: "cover"
    },
    videoControlsContainer: {
        position: "absolute",
        bottom: "40px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: "40px",
        paddingRight: "40px",
    },
    videoControls: {
        width: "100%",
        maxWidth: "700px",
        padding: "24px 16px",
        paddingBottom: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        background: "rgba(255, 255, 255, 0.80)",
        borderRadius: "16px",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        border: "1px solid rgba(255, 255, 255, 0.60)"

        //         border-radius: 16px;
        // border: 1px solid rgba(255, 255, 255, 0.60);
        // background: rgba(255, 255, 255, 0.80);
        // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    },
    videoInfoContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    playPauseBtn: {
        background: "none",
        border: "none",
        color: "white",
        fontSize: "16px",
        cursor: "pointer",
        width: "10px"
    },
    seekBar: {
        height: "4px",
        borderRadius: "5px",
        // background: "#9B9B9B",
        outline: "none",
        cursor: "pointer",
        "-webkit-appearance": "none",
        appearance: "none",
        "&::-webkit-slider-thumb": {
            "-webkit-appearance": "none",
            appearance: "none",
            width: "0",
            height: "0",
        },
        "&::-moz-range-thumb": {
            width: "0",
            height: "0",
        },
    },
    timeDisplay: {
        color: "#000",
        fontSize: "16px",
        fontWeight: 400,
    },
});


export default NativeVideo;
