import * as React from "react";

import { Feature, Map, View } from "ol";
import { Geometry, Point } from "ol/geom";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import { fromLonLat } from "ol/proj";
import OSM from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import { Fill, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import Papa from 'papaparse';
import { getFovFeature, VideoViewerType } from "./helpers";
import PipViewer from "./views/PipViewer";
import ResizableViewer from "./views/ResizableViewer";

interface IProps {
    videoUrl: string;
    dataUrl: string;
    setVideoData: React.Dispatch<React.SetStateAction<{
        viewer: VideoViewerType,
        coordinates: {
            lat: string;
            lng: string;
            alt: string;
        }
    }>>;
}

export const VideoPlayer = ({ videoUrl, dataUrl, setVideoData }: IProps) => {
    const mapEl = React.useRef<HTMLDivElement>(null);
    const [olMap, setOlMap] = React.useState<Map | null>(null);
    const [positionFeature, setPositionFeature] = React.useState<Feature | undefined>();
    const [time, setTime] = React.useState<number>(0);
    const [duration, setDuration] = React.useState<number | undefined>();
    const [droneInfo, setDroneInfo] = React.useState<any | undefined>();
    const [fovLayer, setFovLayer] = React.useState<VectorSource<Geometry> | undefined>();
    const [index, setIndex] = React.useState(0);
    const [currentViewer, setCurrentViewer] = React.useState<VideoViewerType>("vertical")

    React.useEffect(() => {
        Papa.parse(dataUrl, {
            download: true,
            header: true,
            dynamicTyping: true,
            complete: function (results: any) {
                const data: any[] = results.data;
                if (data.length < 2) {
                    return;
                }
                setDroneInfo(data);
            }
        });
    }, []);

    React.useEffect(() => {
        if (!mapEl.current) return;
        if (olMap) { }

        const positionFeature = new Feature();
        positionFeature.setStyle(
            new Style({
                image: new CircleStyle({
                    radius: 6,
                    fill: new Fill({
                        color: '#3399CC',
                    }),
                    stroke: new Stroke({
                        color: '#fff',
                        width: 2,
                    }),
                }),
            }),
        );

        const fovVectorSource = new VectorSource();
        const fovVectorLayer = new VectorLayer({
            source: fovVectorSource,
        });

        const map = new Map({
            target: mapEl.current,
            view: new View({
                center: [0, 0],
                zoom: 15,
            }),
            layers: [
                new TileLayer({
                    visible: true,
                    source: new OSM({
                        cacheSize: 2048,
                    }),
                    zIndex: 0,
                }),
                new VectorLayer({
                    source: new VectorSource({
                        features: [positionFeature],
                    }),
                }),
                fovVectorLayer,
            ],
            controls: [],
            keyboardEventTarget: document,
        });
        setOlMap(map);
        setPositionFeature(positionFeature);
        setFovLayer(fovVectorSource);

        return () => {
            map.setTarget(undefined);
        };
    }, [mapEl.current, currentViewer]);

    const changePositionandFov = (droneInfo: any, index: number) => {
        const pos = fromLonLat([droneInfo[index]['SensorLongitude'], droneInfo[index]['SensorLatitude']]);
        if (positionFeature) {
            positionFeature.setGeometry(new Point(pos));
        }
        if (fovLayer) {
            fovLayer.clear();
            fovLayer.addFeature(getFovFeature(droneInfo, index));
        }
        olMap?.getView().setCenter(pos);
        return;
    }


    React.useEffect(() => {
        if (!droneInfo || droneInfo.length < 2) {
            return;
        }

        if (!time || !duration || !duration) {
            changePositionandFov(droneInfo, 0);
            return;
        }
        const newIndex = seekIndex(time, droneInfo, index);

        setIndex(newIndex);
        setVideoData({
            viewer: currentViewer,
            coordinates: {
                lat: droneInfo[newIndex]['SensorLatitude'],
                lng: droneInfo[newIndex]['SensorLongitude'],
                alt: droneInfo[newIndex]['SensorTrueAltitude'],
            }
        })
        changePositionandFov(droneInfo, newIndex);
    }, [time, duration, droneInfo, positionFeature, fovLayer])

    const changeCurrentViewer = (viewer: VideoViewerType) => {
        setCurrentViewer(viewer);
        setVideoData(prev => ({
            viewer: currentViewer,
            coordinates: { ...prev.coordinates }
        }));
    }

    const seekIndex = (time: number, droneInfo: any[], prevIndex: number) => {
        const startTimestamp = droneInfo[0]['UnixTimeStamp'];
        const currentTimestamp = startTimestamp + time * 1000000;

        return searchIndex(currentTimestamp, droneInfo, 0, droneInfo.length);
    };

    const searchIndex = (timestamp: number, droneInfo: any[], minIdx: number, maxIdx: number): number => {
        if (minIdx >= maxIdx || minIdx === maxIdx - 1) return minIdx;
        const mid = Math.floor((minIdx / 2) + (maxIdx / 2));
        const midTimestamp = droneInfo[mid]['UnixTimeStamp'];
        if (midTimestamp > timestamp) {
            return searchIndex(timestamp, droneInfo, minIdx, mid);
        }
        return searchIndex(timestamp, droneInfo, mid, maxIdx);
    };

    return <>
        {currentViewer === "pip" ?
            <PipViewer mapEl={mapEl}
                nativeVideoOptions={{
                    setDuration,
                    setTime,
                    videoUrl,
                    time,
                    duration,
                }}
                changeCurrentViewer={changeCurrentViewer}
            /> :
            <ResizableViewer mapEl={mapEl}
                videoOptions={{
                    setDuration,
                    setTime,
                    videoUrl,
                    time,
                    duration,
                }}
                orientation={currentViewer}
                changeCurrentViewer={changeCurrentViewer}
            />}

    </>
}