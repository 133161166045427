import React, { useRef, useState } from "react";
import NativeVideo from "../NativeVideo";
import { VideoViewerType } from "../helpers";

interface PipViewerProps {
    mapEl: React.RefObject<HTMLDivElement>;
    nativeVideoOptions: any;
    changeCurrentViewer: (viewer: VideoViewerType) => void;
}

export default function PipViewer(props: PipViewerProps) {
    const { mapEl, nativeVideoOptions, changeCurrentViewer, } = props;

    const [mapPosition, setMapPosition] = useState({ top: 24, right: 24 });
    const [mapSize, setMapSize] = useState({ width: 200, height: 150 });
    const isDraggingRef = useRef(false);
    const isResizingRef = useRef(false);
    const dragStartRef = useRef({ x: 0, y: 0 });
    const resizeStartRef = useRef({ width: 200, height: 150, x: 0, y: 0 });

    const handleMouseDown = (e: React.MouseEvent) => {
        isDraggingRef.current = true;
        dragStartRef.current = { x: e.clientX, y: e.clientY };
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp, { once: true });
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (isDraggingRef.current) {
            const deltaX = dragStartRef.current.x - e.clientX;
            const deltaY = dragStartRef.current.y - e.clientY;

            setMapPosition((prev) => ({
                top: Math.max(0, prev.top - deltaY),
                right: Math.max(0, prev.right + deltaX),
            }));

            dragStartRef.current = { x: e.clientX, y: e.clientY };
        } else if (isResizingRef.current) {
            const deltaX = e.clientX - resizeStartRef.current.x;
            const deltaY = e.clientY - resizeStartRef.current.y;

            setMapSize({
                width: Math.max(200, resizeStartRef.current.width - deltaX),
                height: Math.max(150, resizeStartRef.current.height + deltaY),
            });
        }
    };

    const handleMouseUp = () => {
        isDraggingRef.current = false;
        isResizingRef.current = false;
        document.removeEventListener("mousemove", handleMouseMove);
    };

    const handleResizeMouseDown = (e: React.MouseEvent) => {
        isResizingRef.current = true;
        resizeStartRef.current = {
            width: mapSize.width,
            height: mapSize.height,
            x: e.clientX,
            y: e.clientY,
        };
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp, { once: true });
    };

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                maxHeight: "calc(-168px + 100vh)",
                position: "relative",
                overflow: "hidden",
            }}
        >
            <NativeVideo {...nativeVideoOptions} currentViewer="pip" changeCurrentViewer={changeCurrentViewer} />

            <div
                ref={mapEl}
                style={{
                    position: "absolute",
                    top: `${mapPosition.top}px`,
                    right: `${mapPosition.right}px`,
                    width: `${mapSize.width}px`,
                    height: `${mapSize.height}px`,
                    background: "#e0e0e0",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
            >
                {/* For resizing and dragndrop */}
                {/* <div
                    style={{
                        position: "absolute",
                        top: "8px",
                        right: "8px",
                        width: "20px",
                        height: "20px",
                        background: "#000",
                        borderRadius: "50%",
                        cursor: "grab",
                        zIndex: 1000
                    }}
                    onMouseDown={handleMouseDown}
                ></div>

                <div
                    style={{
                        position: "absolute",
                        bottom: "8px",
                        left: "8px",
                        width: "16px",
                        height: "16px",
                        background: "#000",
                        cursor: "nesw-resize",
                        zIndex: 1000
                    }}
                    onMouseDown={handleResizeMouseDown}
                ></div> */}
            </div>
        </div>
    );
}
